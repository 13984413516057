<template>
  <v-container >
    <v-card class="glass">
      <v-card-title>
        <v-icon left>
          mdi-cog
        </v-icon>
        Ajustes
      </v-card-title>
      <v-divider/>
       <v-tabs  class="glass" :show-arrows="$vuetify.breakpoint.smAndDown"
      :grow="$vuetify.breakpoint.smAndDown">

      <v-tab>
        <v-icon left>
          mdi-account-group
        </v-icon>
        Planes
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-calendar-month
        </v-icon>

        Agenda
      </v-tab>
     <!--  <v-tab>
        <v-icon left>
          mdi-email
        </v-icon>
        E-mail
      </v-tab> -->

      <v-tab-item>
                    <v-divider></v-divider>

        <Subscriptions />
      </v-tab-item>
      <v-tab-item>
                    <v-divider></v-divider>

        <Schedules />
      </v-tab-item>
      <v-tab-item>
                    <v-divider></v-divider>

        <!--         <AlertTemplates/>
 -->
        <EmailTemplates />
      </v-tab-item>
    </v-tabs>
    </v-card>

   


  </v-container>

</template>

<script>
import Schedules from '@/components/Schedules.vue';

import EmailTemplates from '@/components/templates/EmailTemplates.vue';
import AlertTemplates from '@/components/templates/AlertTemplates.vue';

import Subscriptions from '@/views/Subscriptions.vue';
export default {
  components: {
    Schedules,
    Subscriptions,
    EmailTemplates,
    AlertTemplates
  },
  data() {
    return {
      //
    }
  }
}
</script>